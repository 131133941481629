import apiClient from "../services/axios"
import { toRefs, reactive, onMounted, onUnmounted, watch, computed } from 'vue'
import axios from 'axios'
import { useAxios } from '@vueuse/integrations/useAxios';

/**
 * @template T
 * @typedef {import("vue").Ref<T>} Ref<T>
 * 
 * @param {string} url 
 * @param {import("axios").AxiosRequestConfig} axiosOptions 
 * @param {{ immediate: boolean, fetchOnMounted: boolean }} options
 * 
 * @returns {{ 
 *  isLoading: Ref<boolean>,
 *  error: Ref<import("axios").AxiosError | null>,
 *  response: Ref<any | null>,
 *  data: Ref<any>,
 *  execute: (url: string, opts: import("axios").AxiosRequestConfig) => Promise<void>,
 * }}
 */
export const useFetch = (url = null, axiosOptions = {}, { immediate = false, fetchOnMounted = false } = {}) => {
  const state = reactive({
    isLoading: false,
    error: null,
    response: null,
    data: null,
  })

  const execute = async (eUrl = url, aOptions = axiosOptions) => {
    state.error = null
    state.isLoading = true

    try {
      const response = await apiClient.request(eUrl, {
        ...aOptions,
      })
      state.response = response
      state.data = response.data
    } catch (err) {
      if (axios.isCancel(err)) console.error('canceled')
      state.error = err
      state.data = null
    } finally {
      state.isLoading = false
    }
  }


  if (immediate) execute()

  if (fetchOnMounted) {
    onMounted(() => execute())
  }

  return {
    ...toRefs(state),
    execute,
  }
}


/**
 * @template D
 * 
 * @param {string} url 
 * @param {import("axios").AxiosRequestConfig | () => import("axios").AxiosRequestConfig} config 
 * @param {any} initialValue 
 * @param {{ 
 *  abortIfUnmounted: boolean, 
 *  transform: (e: any) =>  any,
 *  watch: undefined | () => any
 * } & import('@vueuse/integrations/useAxios').UseAxiosOptions} options
 * 
 * @return {import('@vueuse/integrations/useAxios').StrictUseAxiosReturn & { state: any, rawExecute: () => Promise<any>}}
 * 
*/
export const useAxiosClient = (url, config = {},  options = {}) => {

  const { transform, abortIfUnmounted = true, watch: thingToWatch, ...opts } = options

  const request = useAxios(url, typeof config !== 'function' ? config : config(), apiClient, opts)
  const execute = () => request.execute(typeof config !== 'function' ? config : config())

  if (abortIfUnmounted) {
    onUnmounted(() => request.abort())
  }

  if (thingToWatch) {
    watch(thingToWatch, () => execute())
  }

  const state = computed(() => {
    if (request.data.value) return transform ? transform(request.data.value) : request.data.value

    return request.data.value
  })

  return {
    ...request,
    rawExecute: request.execute,
    execute,
    state,
  }
}
